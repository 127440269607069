import ApiService from "@/core/services/ApiService";
import { FormData } from "@/core/types/gws-medical/product-management/FormData";
import { ApiResponse } from "@/core/types/misc/ApiResponse";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { defineStore } from "pinia";
import { useAuthenticationStore } from "../../application/useAuthenticationStore";

const authStore = useAuthenticationStore();
export const useMedicalProductFormStore = defineStore({
  id: "medicalProductFormStore",
  state: () => {
    return {
      stateIsLoading: false,
      stateFormData: {} as FormData,
      stateError: false,
      stateErrors: [] as string[],
      stateErrorMsg: "",
      stateUpdateId: "",
    };
  },
  getters: {
    loading(state) {
      return state.stateIsLoading;
    },
    formData(state) {
      return state.stateFormData;
    },
    error(state) {
      return state.stateError;
    },
    errors(state) {
      return state.stateErrors;
    },
    errorMessage(state) {
      return state.stateErrorMsg;
    },
  },
  actions: {
    async insertData() {
      this.stateIsLoading = true;
      try {
        const payload = {
          name: this.formData.name,
          description: this.formData.description,
          facility_id: authStore.user.org_id,
          package_id: this.formData.package_id,
          price: this.formData.price,
          image: this.formData.image,
          is_active: this.formData.is_active,
        };
        const response: AxiosResponse<ApiResponse<any>> = await ApiService.post(
          `${process.env.VUE_APP_GWS_API_BASE_URL}/medical/packages/configuration`,
          payload as AxiosRequestConfig
        );
        if (response.data) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        this.stateErrors.push(error.toString() || "");
        this.stateError = true;
      }
      this.stateIsLoading = false;
    },
    async updateData() {
      this.stateIsLoading = true;
      try {
        const payload = {
          name: this.formData.name,
          description: this.formData.description,
          price: `${this.formData.price as string}`,
          image: this.formData.image,
          is_active: this.formData.is_active,
        };
        const response: AxiosResponse<ApiResponse<any>> =
          await ApiService.patch(
            `${process.env.VUE_APP_GWS_API_BASE_URL}/medical/packages/configuration/${this.stateUpdateId}`,
            payload as AxiosRequestConfig
          );
        if (response.data.meta.code === 200) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        this.stateErrors.push(error.toString() || "");
        this.stateError = true;
      }
      this.stateIsLoading = false;
    },
    async deleteData(id: string) {
      this.stateIsLoading = true;
      try {
        const response: AxiosResponse<ApiResponse<any>> =
          await ApiService.delete(
            `${process.env.VUE_APP_GWS_API_BASE_URL}/medical/packages/configuration/${id}`
          );
        if (response.status === 200) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        this.stateErrors.push(error.toString() || "");
        this.stateError = true;
      }
      this.stateIsLoading = false;
    },
    setFormData(data: FormData) {
      this.stateFormData = {
        name: data.name || "",
        description: data.description || "",
        package_id: data.package_id || "",
        facility_id: data.facility_id || "",
        price: data.price || "",
        image: data.image || "",
        is_active: data.is_active,
      };
    },
    setErrors(errors: string[]) {
      this.stateErrors = errors;
    },
  },
});
